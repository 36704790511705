import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  Avatar,
  IconButton,
  Button,
} from "@mui/material";
import UserIcon from "@mui/icons-material/AccountCircle"; // Import user icon
import BotIcon from "@mui/icons-material/Android"; // Import bot icon

const ChatInterface = ({
  messages,
  addMessage,
  clientSide,
  changeClientToServer,
  canSendMessage,
}) => {
  const [message, setMessage] = useState("");
  const messagesEndRef = useRef(null); // Create a ref for the last message

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, clientSide]);

  const handleSendMessage = () => {
    if (message.trim()) {
      addMessage({ text: message, isUser: true });
      setMessage("");
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        right: 0,
        width: "400px",
        height: "525px",
        border: "1px solid black",
        borderRadius: "10px",
        padding: "10px",
        backgroundColor: "rgba(44, 44, 44, 0.9)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            padding: "10px 0",
            textAlign: "center",
            fontWeight: "bold",
            backgroundColor: "#2c2c2c", // Slightly different background for the heading
            borderRadius: "8px",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)",
          }}
        >
          <Button onClick={changeClientToServer}>
            {clientSide ? "Client" : "Server"}
          </Button>
        </Box>
        <Box>
          <Typography
            variant="h6"
            color="white"
            sx={{
              padding: "10px 0",
              textAlign: "center",
              fontWeight: "bold",
              backgroundColor: "#2c2c2c", // Slightly different background for the heading
              borderRadius: "8px",
              boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)",
              width: "300px",
            }}
          >
            Chat With Your Product
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          maxHeight: "400px", // Set a max-height for the messages container
        }}
      >
        {messages.map((msg, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              justifyContent: msg.isUser ? "flex-end" : "flex-start",
              marginBottom: "10px",
            }}
          >
            <Box
              sx={{
                maxWidth: "80%", // Set a max-width for chat bubbles
                wordBreak: "break-word", // Ensure long words do not cause overflow
              }}
            >
              <Typography
                variant="body1"
                color="white" // Change text color to white for better readability
                sx={{
                  display: "inline-block",
                  padding: "10px", // Add more padding for better spacing
                  borderRadius: "10px",
                  backgroundColor: msg.isUser ? "#3498db" : "#2ecc71", // Update bubble colors
                }}
              >
                {msg.text}
              </Typography>
            </Box>
          </Box>
        ))}
        <div ref={messagesEndRef} />
      </Box>
      <Box
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          handleSendMessage();
        }}
        sx={{
          justifyContent: "space-between",
          marginTop: "10px",
        }}
      >
        <TextField
          variant="outlined"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyPress={handleKeyPress}
          multiline
          rowsMax={4}
          fullWidth
          placeholder="Ask anything ..."
          disabled={!canSendMessage} // Disable the input based on canSendMessage
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "20px",
            },
          }}
          InputProps={{
            style: {
              color: "white",
            },
          }}
        />
        <Typography
          variant="caption"
          display="block"
          gutterBottom
          align="center"
          sx={{ marginTop: "10px" }}
        >
          Powered by Interface
        </Typography>
      </Box>
    </Box>
  );
};

export default ChatInterface;
