import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import { Box, IconButton, TextField } from "@mui/material";
import {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import boardApi from "../api/boardApi";
import EmojiPicker from "../components/common/EmojiPicker";
import Kanban from "../components/common/Kanban";
import { setBoards } from "../redux/features/boardSlice";
import { setFavouriteList } from "../redux/features/favouriteSlice";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import Divider from "@mui/material/Divider";
import ChatInterface from "../components/common/ChatInterface";
import assistantApi from "../api/assistantApi";

let timer;
const timeout = 500;
const getToken = () => localStorage.getItem("token");

const Board = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { boardId } = useParams();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [sections, setSections] = useState([]);
  const [isFavourite, setIsFavourite] = useState(false);
  const [icon, setIcon] = useState("");
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [clientSide, setClientSide] = useState(true);
  // Add a new state to manage if the user can send a message
  const [canSendMessage, setCanSendMessage] = useState(true);

  const boards = useSelector((state) => state.board.value);
  const favouriteList = useSelector((state) => state.favourites.value);

  const kanbanRef = useRef();

  useEffect(() => {
    const getBoard = async () => {
      try {
        const res = await boardApi.getOne(boardId);
        setTitle(res.title);
        setDescription(res.description);
        setSections(res.sections);
        setIsFavourite(res.favourite);
        setIcon(res.icon);
      } catch (err) {
        alert(err);
      }
    };
    getBoard();
  }, [boardId]);

  const changeClientToServer = () => {
    setClientSide(!clientSide);
  };

  const onIconChange = async (newIcon) => {
    let temp = [...boards];
    const index = temp.findIndex((e) => e.id === boardId);
    temp[index] = { ...temp[index], icon: newIcon };

    if (isFavourite) {
      let tempFavourite = [...favouriteList];
      const favouriteIndex = tempFavourite.findIndex((e) => e.id === boardId);
      tempFavourite[favouriteIndex] = {
        ...tempFavourite[favouriteIndex],
        icon: newIcon,
      };
      dispatch(setFavouriteList(tempFavourite));
    }

    setIcon(newIcon);
    dispatch(setBoards(temp));
    try {
      await boardApi.update(boardId, { icon: newIcon });
    } catch (err) {
      alert(err);
    }
  };

  const updateTitle = async (e) => {
    clearTimeout(timer);
    const newTitle = e.target.value;
    setTitle(newTitle);

    let temp = [...boards];
    const index = temp.findIndex((e) => e.id === boardId);
    temp[index] = { ...temp[index], title: newTitle };

    if (isFavourite) {
      let tempFavourite = [...favouriteList];
      const favouriteIndex = tempFavourite.findIndex((e) => e.id === boardId);
      tempFavourite[favouriteIndex] = {
        ...tempFavourite[favouriteIndex],
        title: newTitle,
      };
      dispatch(setFavouriteList(tempFavourite));
    }

    dispatch(setBoards(temp));

    timer = setTimeout(async () => {
      try {
        const response = await boardApi.update(boardId, { title: newTitle });
        return response;
      } catch (err) {
        alert(err);
      }
    }, timeout);
  };

  const updateDescription = async (e) => {
    clearTimeout(timer);
    const newDescription = e.target.value;
    setDescription(newDescription);
    timer = setTimeout(async () => {
      try {
        const response = await boardApi.update(boardId, {
          description: newDescription,
        });
        return response;
      } catch (err) {
        alert(err);
      }
    }, timeout);
  };

  const addFavourite = async () => {
    try {
      const board = await boardApi.update(boardId, { favourite: !isFavourite });
      let newFavouriteList = [...favouriteList];
      if (isFavourite) {
        newFavouriteList = newFavouriteList.filter((e) => e.id !== boardId);
      } else {
        newFavouriteList.unshift(board);
      }
      dispatch(setFavouriteList(newFavouriteList));
      setIsFavourite(!isFavourite);
      return board;
    } catch (err) {
      alert(err);
    }
  };

  const deleteBoard = async () => {
    try {
      const response = await boardApi.delete(boardId);
      if (isFavourite) {
        const newFavouriteList = favouriteList.filter((e) => e.id !== boardId);
        dispatch(setFavouriteList(newFavouriteList));
      }

      const newList = boards.filter((e) => e.id !== boardId);
      if (newList.length === 0) {
        navigate("/boards");
      } else {
        navigate(`/boards/${newList[0].id}`);
      }
      dispatch(setBoards(newList));
      return response;
    } catch (err) {
      alert(err);
    }
  };

  const toggleChatInterface = async () => {
    setIsChatOpen(!isChatOpen);
  };

  const simulateTyping = async (updateTitle, title) => {
    for (let i = 0; i <= title.length; i++) {
      setTimeout(() => {
        // Simulate one keystroke at a time
        updateTitle({ target: { value: title.slice(0, i) } });
      }, 100 * i);
    }
  };

  const addMessageToServerSide = async (newMessage) => {
    try {
      setCanSendMessage(false); // Disable sending messages until the bot responds
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      const processingBot = {
        text: "Processing, please wait...",
        isUser: false,
      };
      setMessages((prevMessages) => [...prevMessages, processingBot]);
      const apiResponse = await assistantApi.message({
        token: getToken(),
        message: newMessage.text,
      });
      const botMessage = {
        text: apiResponse,
        isUser: false,
      };
      setMessages((prevMessages) => [...prevMessages, botMessage]);
      const refreshingBot = {
        text: "Please refresh your page to view the changes ...",
        isUser: false,
      };
      setMessages((prevMessages) => [...prevMessages, refreshingBot]);
      setCanSendMessage(true); // Enable sending messages after the bot responds
    } catch (error) {
      const errorBot = {
        text: "No response received from assistant.",
        isUser: false,
      };
      setMessages((prevMessages) => [...prevMessages, errorBot]);
      setCanSendMessage(true);
    }
  };

  const addMessageToClientSide = async (newMessage) => {
    try {
      setCanSendMessage(false); // Disable sending messages until the bot responds
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      const processingBot = {
        text: "Processing, please wait...",
        isUser: false,
      };
      setMessages((prevMessages) => [...prevMessages, processingBot]);

      await props.onNewMessage(newMessage, (responseMessage) => {
        setMessages((prevMessages) => [...prevMessages, responseMessage]);
        setCanSendMessage(true); // Enable sending messages after the bot responds
      });
    } catch (error) {
      alert("Unknown error. Please try again later ...");
      setCanSendMessage(true);
    }
  };

  useImperativeHandle(ref, () => ({
    updateTitle: async (e) => {
      clearTimeout(timer);
      const newTitle = e.target.value;
      setTitle(newTitle);

      let temp = [...boards];
      const index = temp.findIndex((e) => e.id === boardId);
      temp[index] = { ...temp[index], title: newTitle };

      if (isFavourite) {
        let tempFavourite = [...favouriteList];
        const favouriteIndex = tempFavourite.findIndex((e) => e.id === boardId);
        tempFavourite[favouriteIndex] = {
          ...tempFavourite[favouriteIndex],
          title: newTitle,
        };
        dispatch(setFavouriteList(tempFavourite));
      }

      dispatch(setBoards(temp));

      try {
        // Pass the updateTitle function to simulateTyping
        await simulateTyping(updateTitle, newTitle);

        // Update the backend after the typing simulation is done
        const response = await boardApi.update(boardId, { title: newTitle });
        return response;
      } catch (err) {
        alert(err);
      }
    },
    updateDescription: async (e) => {
      clearTimeout(timer);
      const newDescription = e.target.value;
      setDescription(newDescription);

      try {
        await simulateTyping(updateDescription, newDescription);

        const response = await boardApi.update(boardId, {
          description: newDescription,
        });
        return response;
      } catch (err) {
        alert(err);
      }
    },
    addFavourite,
    deleteBoard,
    kanbanRef: kanbanRef,
  }));

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <IconButton variant="outlined" onClick={addFavourite}>
          {isFavourite ? (
            <StarOutlinedIcon color="warning" />
          ) : (
            <StarBorderOutlinedIcon />
          )}
        </IconButton>
        <Box display="flex">
          <IconButton variant="outlined" color="error" onClick={deleteBoard}>
            <DeleteOutlinedIcon />
          </IconButton>
          <Divider
            orientation="vertical"
            variant="middle"
            aria-hidden="true"
            color="pink"
            flexItem
          />
          <IconButton variant="outlined" onClick={toggleChatInterface}>
            <ChatOutlinedIcon />
          </IconButton>
        </Box>
      </Box>
      <Box sx={{ padding: "10px 50px" }}>
        <Box>
          {/* emoji picker */}
          <EmojiPicker icon={icon} onChange={onIconChange} />
          <TextField
            value={title}
            onChange={updateTitle}
            placeholder="Untitled"
            variant="outlined"
            fullWidth
            id="update-title"
            sx={{
              "& .MuiOutlinedInput-input": { padding: 0 },
              "& .MuiOutlinedInput-notchedOutline": { border: "unset " },
              "& .MuiOutlinedInput-root": {
                fontSize: "2rem",
                fontWeight: "700",
              },
            }}
          />
          <TextField
            value={description}
            onChange={updateDescription}
            placeholder="Add a description"
            variant="outlined"
            multiline
            fullWidth
            id="update-description"
            sx={{
              "& .MuiOutlinedInput-input": { padding: 0 },
              "& .MuiOutlinedInput-notchedOutline": { border: "unset " },
              "& .MuiOutlinedInput-root": { fontSize: "0.8rem" },
            }}
          />
        </Box>
        <Box>
          {/* Kanban board */}
          <Kanban data={sections} boardId={boardId} ref={kanbanRef} />
          {isChatOpen ? (
            <ChatInterface
              messages={messages}
              addMessage={
                clientSide ? addMessageToClientSide : addMessageToServerSide
              }
              clientSide={clientSide}
              changeClientToServer={changeClientToServer}
              canSendMessage={canSendMessage}
            />
          ) : null}
        </Box>
      </Box>
    </>
  );
});

export default Board;
