import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./css/custom-scrollbar.css";
import CssBaseLine from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AppLayout from "./components/layout/AppLayout";
import AuthLayout from "./components/layout/AuthLayout";
import Home from "./pages/Home";
import Board from "./pages/Board";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import React, {
  useEffect,
  createContext,
  useContext,
  useState,
  useRef,
} from "react";
import boardApi from "./api/boardApi";

import OpenAI from "openai";

const AppContext = createContext();

export function useAppContext() {
  return useContext(AppContext);
}

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
});

const tools = [
  // board related
  {
    type: "function",
    function: {
      name: "navigateBoard",
      description: "Navigate to the Board with specified ID.",
      parameters: {
        type: "object",
        properties: {
          boardId: {
            type: "string",
            description: "The ID of the board e.g. 65b5514122e316162e580c5e",
          },
        },
        required: ["boardId"],
      },
    },
  },
  {
    type: "function",
    function: {
      name: "getAllBoards",
      description:
        "Get all the boards. This function is helpful to fetch the list of all the boards.",
    },
  },
  {
    type: "function",
    function: {
      name: "getOneBoard",
      description:
        "Get a specific Board along with it's Sections and Tasks. This function is helpful to fetch a specific Board with it's Section and Tasks.",
      parameters: {
        type: "object",
        properties: {
          boardId: {
            type: "string",
            description: "The ID of the board e.g. 65b5514122e316162e580c5e",
          },
        },
        required: ["boardId"],
      },
    },
  },
  {
    type: "function",
    function: {
      name: "addBoard",
      description:
        "This function creates a new Board with default Title, Icon and Description. Example User Input: `Create a new Board.`",
    },
  },
  {
    type: "function",
    function: {
      name: "updateBoardTitle",
      description:
        "Update a particular Board's Title. Example User Input: `Rename my Groceries Board to FMCG`",
      parameters: {
        type: "object",
        properties: {
          e: {
            type: "object",
            properties: {
              target: {
                type: "object",
                properties: {
                  value: {
                    type: "string",
                    description: "The new title of the board e.g. FMCG",
                  },
                },
                required: ["value"],
              },
            },
            required: ["target"],
          },
        },
        required: ["e"],
      },
    },
  },
  {
    type: "function",
    function: {
      name: "updateBoardDescription",
      description:
        "Update a particular Board's Description. Example User Input: `Update To-Do Board's Description to Kanban`",
      parameters: {
        type: "object",
        properties: {
          e: {
            type: "object",
            properties: {
              target: {
                type: "object",
                properties: {
                  value: {
                    type: "string",
                    description:
                      "The new description of the board e.g. Kanban Board for To-Do",
                  },
                },
                required: ["value"],
              },
            },
            required: ["target"],
          },
        },
        required: ["e"],
      },
    },
  },
  {
    type: "function",
    function: {
      name: "addBoardFavourite",
      description:
        "Adds or Removes Board to favourite. Example User Input: `Mark this board as favourite` Example User Input: `Unfavourite this Board`",
    },
  },
  {
    type: "function",
    function: {
      name: "deleteBoard",
      description:
        "Deletes a specific Board. Example User Input: `Delete the board Kanban`",
    },
  },
  // section related
  {
    type: "function",
    function: {
      name: "createSection",
      description:
        "Create a new section with default Title. Example User Input: `Create a new Section under the Board called To-Do`",
    },
  },
  {
    type: "function",
    function: {
      name: "deleteSection",
      description:
        "Delete a particular section. This function is helpful to delete a specific Section. Example User Input: `Delete the Section called Fruits in the Groceries Board`",
      parameters: {
        type: "object",
        properties: {
          sectionId: {
            type: "string",
            description:
              "The id of the Section of which the task will be deleted e.g. 65bc90b2628fe00cda949735",
          },
        },
        required: ["sectionId"],
      },
    },
  },
  {
    type: "function",
    function: {
      name: "updateSectionTitle",
      description:
        "Update a particular Section's Title. Example User Input: `Update To-Do Section's Title to Done`",
      parameters: {
        type: "object",
        properties: {
          e: {
            type: "object",
            properties: {
              target: {
                type: "object",
                properties: {
                  value: {
                    type: "string",
                    description: "The new title of the section e.g. Done",
                  },
                },
                required: ["value"],
              },
            },
            required: ["target"],
          },
          sectionId: {
            type: "string",
            description: "The id of the section e.g. 65bc90b2628fe00cda949735",
          },
        },
        required: ["e", "sectionId"],
      },
    },
  },
  // task related
  {
    type: "function",
    function: {
      name: "onClickTask",
      description: "Opens the Task Modal for the specific Task.",
      parameters: {
        type: "object",
        properties: {
          taskId: {
            type: "string",
            description:
              "The id of the Task for which the Task Modal is to be opened e.g. 65bd5aa0d3f2f5952015bffb",
          },
        },
        required: ["taskId"],
      },
    },
  },
  {
    type: "function",
    function: {
      name: "createTask",
      description:
        "Create a new task with default Title and Content. Example User Input: `Create a new task in the Progress Section of the Kanban Board`",
      parameters: {
        type: "object",
        properties: {
          sectionId: {
            type: "string",
            description:
              "The ID of the Section in which the Task is to be created e.g. 65bd3f0fd3f2f5952015be1f",
          },
        },
        required: ["sectionId"],
      },
    },
  },
  {
    type: "function",
    function: {
      name: "updateTaskTitle",
      description:
        "Update a particular Task's Title. Example User Input: `Update the Task named Untitled to Ship in the Section called Progress in the Kanban Board`",
      parameters: {
        type: "object",
        properties: {
          e: {
            type: "object",
            properties: {
              target: {
                type: "object",
                properties: {
                  value: {
                    type: "string",
                    description: "The new title of the task e.g. Ship",
                  },
                },
                required: ["value"],
              },
            },
            required: ["target"],
          },
        },
        required: ["e"],
      },
    },
  },
  {
    type: "function",
    function: {
      name: "deleteTask",
      description: "Deletes the Task for which the Task Modal which is open.",
    },
  },
  {
    type: "function",
    function: {
      name: "closeModal",
      description:
        "This is the function that runs after a Task Modal is closed.",
    },
  },
];

function App() {
  const theme = createTheme({
    palette: { mode: "dark" },
  });

  const [aiAssistant, setAiAssistant] = useState(null);
  const [aiThread, setAiThread] = useState(null);
  const [messages, setMessages] = useState([]);

  const boardRef = useRef();
  const addBoardRef = useRef();

  useEffect(() => {
    const kanbanAssistant = async () => {
      const kbAssistant = await openai.beta.assistants.create({
        name: "Client Side Kanban Assistant",
        instructions: `You are a Kanban Assistant. You can be asked to perform CRUD operations on the Kanban Platform.
        Users can have multiple Boards. A Board can have multiple Sections. A Section can have multiple Tasks.
        Users can ask you to do multiple actions within the same prompt. You should break down the actions asked into sub-action and call the API endpoints appropriately. You should not use parallel function calling. Do one task end to end before moving on to the next.
        Complete a set of actions completely before moving onto the next one.If the User prompt is not specific, ask the user for more specifc details. You should never ask the User for any ID.
        To do any particular change to a Board, Section or Task, you must navigate to the parent Board of the required Board or Section or Task using the getAllBoards and navigateBoard functions. 
        Tasks can only be updated or deleted after opening the Task Modal. Remember to close the Task Modal after any updates. You must not close the Task Modal after deletion, it automatically closes.
        Example User Prompt: 'Can you create a new Board called Courses, add a new Section to it called Class 5, and add a Task to it called Check Marks'. Example API Calls: ADD BOARD -> NAVIGATE TO SPECIFIC BOARD -> UPDATE BOARD TITLE -> CREATE SECTION -> UPDATE SECTION TITlE -> CREATE TASK -> OPEN TASK MODAL -> UPDATE TASK TITLE -> CLOSE TASK MODAL`,
        tools: tools,
        model: "gpt-4-turbo-preview",
      });
      return kbAssistant;
    };

    const kanbanAssistantThread = async () => {
      const kbAssistantThread = await openai.beta.threads.create();
      return kbAssistantThread;
    };

    kanbanAssistant()
      .then((kbAssistant) => {
        setAiAssistant(kbAssistant);
      })
      .catch((error) => {
        console.log(error);
      });

    kanbanAssistantThread()
      .then((kbAssistantThread) => {
        setAiThread(kbAssistantThread);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const fnMapping = {
    // board related
    navigateBoard: (id) => {
      const response =
        addBoardRef?.current?.addBoardRef?.current?.setActiveIndex?.(id);
      return response;
    },
    getAllBoards: () => boardApi.getAll(),
    getOneBoard: (id) => boardApi.getOne(id),
    addBoard: () => addBoardRef?.current?.addBoardRef?.current?.addBoard?.(),
    updateBoardTitle: (e) => boardRef?.current?.updateTitle?.(e),
    updateBoardDescription: (e) => boardRef?.current?.updateDescription?.(e),
    addBoardFavourite: () => boardRef?.current?.addFavourite?.(),
    deleteBoard: () => boardRef?.current?.deleteBoard?.(),
    // section related
    createSection: () =>
      boardRef?.current?.kanbanRef?.current?.createSection?.(),
    deleteSection: (id) =>
      boardRef?.current?.kanbanRef?.current?.deleteSection?.(id),
    updateSectionTitle: (e, sectionId) =>
      boardRef?.current?.kanbanRef?.current?.updateSectionTitle?.(e, sectionId),
    // task related
    createTask: (id) => boardRef?.current?.kanbanRef?.current?.createTask?.(id),
    onClickTask: (id) =>
      boardRef?.current?.kanbanRef?.current?.onClickTask?.(id),
    deleteTask: () =>
      boardRef?.current?.kanbanRef?.current?.taskModalRef?.current?.deleteTask?.(),
    closeModal: () =>
      boardRef?.current?.kanbanRef?.current?.taskModalRef?.current?.onClose?.(),
    updateTaskTitle: (e) =>
      boardRef?.current?.kanbanRef?.current?.taskModalRef?.current?.updateTaskTitle?.(
        e,
      ),
  };

  const aiContext = {
    aiAssistant,
    aiThread,
  };

  const handleNewMessage = async (newMessage, callback) => {
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    const receivedMessage = newMessage.text;
    let responseMessage;

    await openai.beta.threads.messages.create(aiThread.id, {
      role: "user",
      content: receivedMessage
    });

    const run = await openai.beta.threads.runs.create(aiThread.id, {
      assistant_id: aiAssistant.id,
    });

    let runStatus = await openai.beta.threads.runs.retrieve(
      aiThread.id,
      run.id,
    );

    while (runStatus.status !== "completed") {
      await new Promise((resolve) => {
        setTimeout(resolve, 1000);
      });
      runStatus = await openai.beta.threads.runs.retrieve(aiThread.id, run.id);
      if (runStatus.status === "requires_action") {
        const toolCalls =
          runStatus.required_action.submit_tool_outputs.tool_calls;
        const toolOutputs = [];

        for (const toolCall of toolCalls) {
          const functionName = toolCall.function.name;
          const args = JSON.parse(toolCall.function.arguments);
          const argsArray = Object.keys(args).map((key) => args[key]);
          const output = await fnMapping[functionName].apply(null, argsArray);
          // console.log(
          //   "fn name: ",
          //   functionName,
          //   "\n args ",
          //   argsArray,
          //   "\n output: ",
          //   output,
          // );
          toolOutputs.push({
            tool_call_id: toolCall.id,
            output: JSON.stringify(output),
          });
        }

        await openai.beta.threads.runs.submitToolOutputs(aiThread.id, run.id, {
          tool_outputs: toolOutputs,
        });
        continue;
      }

      if (["failed", "cancelled", "expired"].includes(runStatus.status)) {
        const failedMsg = {
          text: "No response received from the assistant.",
          isUser: false,
        };
        setMessages((prevMessages) => [...prevMessages, failedMsg]);
        if (callback && typeof callback === "function") {
          callback(responseMessage);
        }
        break;
      }
    }

    const messageList = await openai.beta.threads.messages.list(aiThread.id);
    const lastMessageForRun = messageList.data
      .filter(
        (message) => message.run_id === run.id && message.role === "assistant",
      )
      .pop();

    if (lastMessageForRun) {
      responseMessage = {
        text: lastMessageForRun.content[0].text.value,
        isUser: false,
      };
      setMessages((prevMessages) => [...prevMessages, responseMessage]);
      if (callback && typeof callback === "function") {
        callback(responseMessage);
      }
    } else if (!["failed", "cancelled", "expired"].includes(runStatus.status)) {
      const failedMsg = {
        text: "No response received from the assistant.",
        isUser: false,
      };
      setMessages((prevMessages) => [...prevMessages, failedMsg]);
      if (callback && typeof callback === "function") {
        callback(responseMessage);
      }
    }
  };

  return (
    <AppContext.Provider value={aiContext}>
      <ThemeProvider theme={theme}>
        <CssBaseLine />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<AuthLayout />}>
              <Route path="login" element={<Login />} />
              <Route path="signup" element={<Signup />} />
            </Route>
            <Route path="/" element={<AppLayout ref={addBoardRef} />}>
              <Route index element={<Home />} />
              <Route path="boards" element={<Home />} />
              <Route
                path="boards/:boardId"
                element={
                  <Board ref={boardRef} onNewMessage={handleNewMessage} />
                }
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </AppContext.Provider>
  );
}

export default App;
